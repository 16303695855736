/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, PriceWrap, Image, Button, Subtitle } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Geometrie kol"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="pb--50 pt--60" name={"uvod"}>
          
          <ColumnWrap className="column__flex --center el--1 pb--0 pl--0 pr--0 pt--0 flex--center" style={{"maxWidth":1280}} columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim5 --anim-s5 pb--0 pt--0" anim={"5"} animS={"5"} style={{"maxWidth":900}}>
              
              <Title className="title-box fs--72" content={"Geometrie kol&nbsp;"}>
              </Title>

              <Text className="text-box fs--18" content={"Měření a seřízení geometrie kol je zejména důležité pokud jste na vozidle měnily některé&nbsp; části náprav nebo se vám nerovnoměrně sjíždějí pneumatiky pak je nezbytné seřídit sbíhavost a celkovou geometri kol.<br><br>Pracujeme se špičkovým velice přesným italským stojem&nbsp;Ravaglioli&nbsp;<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"obsah-1"} style={{"backgroundColor":"var(--color-blend--95)"}}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":800}}>
              
              <Text className="text-box" content={"Kontrola a seřízení geometrie kol je nastavení úhlů&nbsp; seřizovatelných částí zavěšení kol vozidel dle&nbsp; parametrů&nbsp; udaných výrobcem vozidla a zároveň&nbsp; kontrola&nbsp; opotřebení pneumatik . Geometrii kol vozidla je nezbytné kontrolovat minimálně jednou ročně zabráníte tak nerovnoměrnému sjíždění pneumatik.&nbsp;<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--left pb--60 pt--60" name={"d8p481aqckl"}>
          
          <ColumnWrap className="column__flex --center el--2 flex--top" columns={"2"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box fs--20" style={{"maxWidth":652}} content={"NEBEZPEČÍ CHYBNĚ SEŘÍZENÉ GEOMETRIE:"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":650}} content={"- Rychlé a nesymetrické opotřebení vašich pneumatik\n<br>- Předčasné opotřebení částí systému řízení\n<br>- Nižší jízdní stabilita"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
              
              <Title className="title-box fs--20" content={"VÝHODY SPRÁVNĚ SEŘÍZENÉ GEOMETRIE:"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":650}} content={"- Menší míra opotřebení pneumatik <br>- Lepší jízdní stabilita\nVětší jízdní komfort\n<br>- Nižší spotřeba paliva"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--left pb--60 pt--60" name={"840tyj2f0um"}>
          
          <ColumnWrap className="column__flex --center el--2 flex--top" columns={"2"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box fs--20" content={"Odklon kola"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":650}} content={"\nOdklon kola je úhel, který ve směru jízdy svírá svislá osa kola od svislé roviny. Pokud je tento odklon od svislé roviny příliš velký, dochází k nerovnoměrnému opotřebení běhounu."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
              
              <Title className="title-box fs--20" content={"Sbíhavost"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":650}} content={"Sbíhavost se měří jako rozdíl vzdáleností kol na předním a zadním okraji ráfku ve výši poloměru kol nad vozovkou. Nastavení sbíhavosti kol ovlivňuje ovladatelnost vozidla. Při sbíhavosti jsou kola seřízena tak, že je vpředu jejich vzdálenost menší než vzadu. Sbíhavost způsobuje v zatáčce nedotáčivost vozidla. Pokud je hodnota sbíhavosti zvetšená, začne se běhoun nerovnoměrně opotřebovávat."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"1ckkkrj0ckq"}>
          
          <ColumnWrap className="column__flex el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <PriceWrap style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"KONTROLA GEOMETRIE BEZ SEŘÍZENÍ"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"800,— Kč"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"SEŘÍZENÍ PŘEDNÍ NÁPRAVY"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"1290,— Kč"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"SEŘÍZENÍ PŘEDNÍ A ZADNÍ NÁPRAVY"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"2000,— Kč"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"SEŘÍZENÍ SUV"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"2500,— Kč"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"SEŘÍZENÍ DODÁVKA&nbsp;"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"1800,— Kč<br>"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"xruj7hrcqa"}>
          
          <ColumnWrap className="column__flex el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <PriceWrap style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                </ColumnWrapper>

                <ColumnWrapper className="--right">
                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"SEŘÍZENÍ Porsche, Bentley, Rolls royce ,&nbsp;Lamborghini, Tesla a jiné neobvyklé vozy&nbsp; bohužel neseřizujeme !!!&nbsp;"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"0,— Kč"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"PŘÍPLATEK ZA VOZIDLA - BMW - AUDI - MERCEDES&nbsp;"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"250,— Kč"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"PŘÍPLATEK&nbsp; ZA NAHŘÍVÁNÍ A ROZHÝBÁNÍ ZAREZLÝCH ČÁSTÍ&nbsp;"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"300,— Kč"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"SEŘÍZENÍ GEOMETRIE NA OBYTNÉM VOZIDLE&nbsp;"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"2000,— Kč"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"cbopfs71zh7"} layout={"l9"}>
          
          <ColumnWrap className="column__flex --center el--2" columns={"2"}>
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/20344/9f59f070239845c6a847de9a814f21d8_s=660x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/20344/9f59f070239845c6a847de9a814f21d8_s=350x_.jpg 350w, https://cdn.swbpg.com/t/20344/9f59f070239845c6a847de9a814f21d8_s=660x_.jpg 660w, https://cdn.swbpg.com/t/20344/9f59f070239845c6a847de9a814f21d8_s=860x_.jpg 860w"}>
              </Image>

              <Text className="text-box text-box--center fs--16 w--300 mt--06" content={""}>
              </Text>

              <Image src={"https://cdn.swbpg.com/t/i/template/156/img-2_s=350x_.jpg"} svg={false} ratio={"2:3"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/156/img-2_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/156/img-2_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/156/img-2_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/156/img-2_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/156/img-2_s=2000x_.jpg 2000w"}>
              </Image>

              <Text className="text-box text-box--center fs--16 w--300 mt--06" content={""}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/20344/03f989825d984656ad45cf3948d47a0a_s=660x_.JPG"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/20344/03f989825d984656ad45cf3948d47a0a_s=350x_.JPG 350w, https://cdn.swbpg.com/t/20344/03f989825d984656ad45cf3948d47a0a_s=660x_.JPG 660w, https://cdn.swbpg.com/t/20344/03f989825d984656ad45cf3948d47a0a_s=860x_.JPG 860w"}>
              </Image>

              <Text className="text-box text-box--center fs--16 w--300 mt--06" content={""}>
              </Text>

              <Image src={"https://cdn.swbpg.com/t/i/template/156/img-1_s=350x_.jpg"} svg={false} ratio={"3:2"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/156/img-1_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/156/img-1_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/156/img-1_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/156/img-1_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/156/img-1_s=2000x_.jpg 2000w"}>
              </Image>

              <Text className="text-box text-box--center fs--16 w--300 mt--06" content={""}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-1odcdv6 css-42e4bw pb--80 pt--80" name={"kontakt"} parallax={false} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/11212/6c089215f41d4cfbb338c78781278a98_ove=18191dx52__s=350x_.jpeg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/11212/6c089215f41d4cfbb338c78781278a98_ove=18191dx52__s=660x_.jpeg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/11212/6c089215f41d4cfbb338c78781278a98_ove=18191dx52__s=860x_.jpeg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/11212/6c089215f41d4cfbb338c78781278a98_ove=18191dx52__s=1400x_.jpeg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/11212/6c089215f41d4cfbb338c78781278a98_ove=18191dx52__s=2000x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/11212/6c089215f41d4cfbb338c78781278a98_ove=18191dx52__s=660x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/11212/6c089215f41d4cfbb338c78781278a98_ove=18191dx52__s=1400x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/11212/6c089215f41d4cfbb338c78781278a98_ove=18191dx52__s=3000x_.jpeg);
    }
  
background-position: 50% 50%;
    `}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim7 --anim-s2" anim={"7"} animS={"2"}>
              
              <Title className="title-box fs--72 mt--16" style={{"maxWidth":966}} content={"<span style=\"color: white;\">Máte dotaz?</span><br>"}>
              </Title>

              <Button className="btn-box btn-box--hvr2 btn-box--shape5 fs--22" url={"https://autoservisblaza.cz/kontakt"} href={"https://autoservisblaza.cz/kontakt"} content={"Kontaktovat"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"paticka"} style={{"backgroundColor":"var(--color-supplementary)"}}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s2 el--3" anim={"2"} animS={"2"} style={{"maxWidth":1420}} columns={"3"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box" content={"<span style=\"color: var(--color-custom-2);\">Autoservis Bláža Ostrava - Radvanice&nbsp;</span><br>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":366}} content={"<span style=\"color: var(--color-custom-2);\">Pro Vaše auto uděláme vše, co budeme moct.</span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box" content={"<span style=\"color: var(--color-dominant);\">O nás</span>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":366}} content={"<span style=\"color: var(--color-custom-2);\">Jsme Malý a nezávislý autoservis  osobních i užitkových vozidel. \nMěření a seřízení geometrie kol \nServis čištění a plnění klimatizací \nRychloservis\nPneuservis\nNástřiky podvozku a dutin \nStrojní regenerace filtru pevných částic DPF\na jiné.&nbsp;</span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--right" style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box" content={"<span style=\"color: var(--color-dominant);\">Kontakt</span>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":473}} content={"<span style=\"color: var(--color-custom-2);\">+420 777 321 587\nLihovarská  1378/11,  Ostrava - Radvanice \nAreál Bánských strojíren ( BASTRO )<br>Tel: <a href=\"+420777321587\">+420 777 321 587</a>&nbsp;</span><br>"}>
              </Text>

              <Text className="text-box" content={"<span style=\"color: var(--color-custom-2);\">IČ: 63471710</span><span style=\"color: white;\"><br></span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}